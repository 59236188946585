
<template>
  <div style="background: #303030;min-height: 100vh">
      <div class="header_css">
        <img @click="toBack" src="../../assets/back.png" alt="">
        <h3 style="color: #ffffff;">Relatório</h3>
        <div style="width: 1px"></div>
      </div>
<!--    <div class="containerDD">-->
<!--        <div class="p1" :class="{ 'fixed': isFixed }">-->
<!--          <div @click="changeTab(1)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 1 }" class="colorCSS">Detalhes da Conta</div>-->
<!--          <div @click="changeTab(2)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 2 }" class="colorCSS">Recordes de Apostas</div>-->
<!--          <div @click="changeTab(3)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 3 }" class="colorCSS">Relatório</div>-->
<!--        </div>-->
<!--      </div>-->
      <div v-if="userBill.length<=0" class="d-flex1" style="background:#303030;min-height: 820px;">
          <img style="width:150px" src="@/assets/nothing.png">
          <p>Sem Registros</p>
      </div>
    <div v-else style="margin: 10px;overflow: hidden;overflow-y: auto;height: 100vh">
      <div :class="{'bill_div' :true,'bill_div_ccc':index % 2 != 0}"  v-for="(item,index) in userBill">
        <div>
          <div style="height: 18px;"></div>
          <div>{{item.createTime}}</div>
        </div>
        <div style="text-align: right">
          <div style="color: #fff">{{ item.changeBalance }}</div>
          <div v-if="item.type ==0">completar</div>
          <div v-else-if="item.type ==1">retirar</div>
          <div v-else-if="item.type ==2">Retirada e reembolso</div>
          <div v-else-if="item.type ==3">Jogo para vencer</div>
          <div v-else-if="item.type ==4">jogo perdido</div>
          <div v-else-if="item.type ==5">Participação nos lucros da agência</div>
          <div v-else-if="item.type ==6">Recompensa do primeiro depósito</div>
          <div v-else-if="item.type ==7">Recompensas de baú</div>
          <div v-else-if="item.type ==8">Resgate financeiro</div>
          <div v-else-if="item.type ==15">Recompensa de upgrade VIP</div>
          <div v-else>Recompensa em dinheiro de resgate</div>
        </div>
      </div>
    </div>
<!--    <div v-if="acNum==2">-->

<!--      <div class="d-flex1">-->
<!--        <img style="width:150px" src="@/assets/nothing.png">-->
<!--        <p>Sem Registros</p>-->

<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="acNum==3">-->
<!--      <div class="d-flex1">-->
<!--        <img style="width:150px" src="@/assets/nothing.png">-->
<!--        <p>Sem Registros</p>-->

<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import {getUserBill} from "@/api";

export  default {
  data(){
    return{
      acNum:'',
      userBill:'',
      isFixed: false,
    }
  },
  mounted() {
    if(this.$route.query.num!=null){
      this.acNum = this.$route.query.num
    }else {
      this.acNum = 1;
    }
    console.log("this.acNum:"+this.acNum)
    this.getUserBill();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    toBack(){
      this.$router.go(-1)
    },
    changeTab(num){
      this.acNum = num
    },
    getUserBill(){
      getUserBill().then(res=>{
        this.userBill = res.rows
      })
    },
    handleScroll() {
      // 监听页面滚动事件，并根据需要设置 isFixed 状态
      if (window.scrollY > 0) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.containerDD{
  position: relative; /* 用于定位 p1 */
  height: 50px; /* 示例高度，根据实际情况调整 */
}
.header_css{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #000;
  border-bottom: 1px solid #ccc;
}
.p1{
  display: flex;
  background: #1c1c1c;
  //border-bottom: 1px solid #ccc;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  //background-color: #ffffff;
  z-index: 1000; /* 设置一个比较大的 z-index，保证 p1 在最上层 */
  /* 其他样式属性可以根据需要设置 */
  div{
    padding: 10px;
    height: 100%;
  }
  .colorCSS{
    color: #fff;
    cursor: pointer;
  }
  .activeDiv{
    color: #FF9800;
    border-bottom: 1px solid;
  }
}
.bill_div_ccc{
  background: #cbcbcb !important;
}
.bill_div{
  padding: 10px;
  width: 100%;
  display: flex;
  background: #1c1c1c;
  margin: 10px 0;
  font-size: 12px;
  justify-content: space-between;
  border-radius: 10px;
  div{
    color: #777777;
  }
}
.d-flex1{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 820px;
  flex-direction: column;
}
.fixed {
  position: fixed;

}
@media screen and (min-width: 751px) {
  .fixed {
    position: fixed;
    width: 435px !important;
    left: 50%;
    transform: translateX(-50%);
  }
}
.d-flex1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 420px;
    flex-direction: column;
    background: #303030;
}
</style>
